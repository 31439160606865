.m-areas>div.m-area>div.m-panel>div.m-panel-controls>.m-control.m-container.m-topographicprofile-container button {
    color: white;
    background-color: #71a7d3 !important;
    opacity: 1;
    border-radius: 40px;
    font-family: g-plugin-perfiltopografico !important;
    outline: none;
    border: 2px solid white;
}

.m-areas>div.m-area>div.m-panel>div.m-panel-controls>.m-control.m-container.m-topographicprofile-container>button.activated {
    background-color: #D1E6F8 !important;
    color: #364B5F !important;
    font-family: g-plugin-perfiltopografico !important;
}

/* NUEVO */
.ol-control.ol-profil {
    position: absolute;
    top: 0.5em;
    right: 5em;
    text-align: right;
    overflow: hidden;
}

.ol-profil .ol-inner {
    position: relative;
    padding: 0.5em;
    font-size: 0.8em;
}

.ol-control.ol-profil .ol-inner {
    display: block;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 2px 2px;
}

.ol-control.ol-profil.ol-collapsed .ol-inner {
    display: none;
}

.ol-profil canvas {
    display: block;
}

.ol-profil button {
    display: none;
    position: absolute;
    right: 2px;
}

.ol-profil button.profile-close-button {
    position: absolute;
    right: 2px;
    top: 0px;
    display: block !important;
    color: #676767;
    border: none;
    background-color: transparent;
}

.ol-profil button.profile-close-button:hover {
    transform: scale(1.2);
}

.ol-profil button.profile-close-button:after {
    content: "X";
}

.ol-profil button.profile-close-button:focus {
    outline: none;
}

.ol-profil button.profile-download-button {
    position: absolute;
    right: 20px;
    top: 0px;
    display: block !important;
    color: #676767;
    border: none;
    background-color: transparent;
}

.ol-profil button.profile-download-button:hover {
    transform: scale(1.2);
}

.ol-profil button.profile-download-button:after {
    content: '\e90e';
    font-family: "g-plugin-perfiltopografico";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ol-profil button.profile-download-button:focus {
    outline: none;
}

.ol-profil.ol-collapsed button {
    position: static;
}

.ol-profil .ol-profilbar,
.ol-profil .ol-profilcursor {
    position: absolute;
    pointer-events: none;
    width: 1px;
    display: none;
}

.ol-profil .ol-profilcursor {
    width: 0;
    height: 0;
}

.ol-profil .ol-profilcursor:before {
    content: "";
    pointer-events: none;
    display: block;
    margin: -2px;
    width: 5px;
    height: 5px;
}

.ol-profil .ol-profilbar,
.ol-profil .ol-profilcursor:before {
    background: red;
}

.ol-profil table {
    text-align: center;
    width: 100%;

    margin-top: 10px;
    font-size: 13px;
    color: black;
    font-weight: 600;
}

.ol-profil table span {
    display: block;
}

.ol-profilpopup {
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0.5em;
    padding: 0 0.5em;
    position: absolute;
    top: -1em;
    white-space: nowrap;
}

.ol-profilpopup.ol-left {
    right: 0;
}

.ol-profil table td {
    padding: 0 2px;
}

.ol-profil table .track-info {
    display: table-row;
}

.ol-profil table .point-info {
    display: none;
}

.ol-profil .over table .track-info {
    display: none;
}

.ol-profil .over table .point-info {
    display: table-row;
}

.ol-profil p {
    text-align: center;
    margin: 0;
    font-weight: bold;
    color: #A01001;
    padding: 8px 0;
    font-size: 16px;
    background-color: #ffffffb5;
}


/* fonts*/
@font-face {
    font-family: 'g-plugin-perfiltopografico';
    src: url('../fonts/g-plugin-perfiltopografico.eot?12172227');
    src: url('../fonts/g-plugin-perfiltopografico.eot?12172227#iefix') format('embedded-opentype'),
        url('../fonts/g-plugin-perfiltopografico.ttf?12172227') format('truetype'),
        url('../fonts/g-plugin-perfiltopografico.woff?12172227') format('woff'),
        url('../fonts/g-plugin-perfiltopografico.svg?12172227#g-plugin-perfiltopografico') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="g-plugin-perfiltopografico"],
[class*=" g-plugin-perfiltopografico"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'g-plugin-perfiltopografico' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-chart:before {
    content: '\e800';
}

.icon-download:before {
    content: '\e90e';
}